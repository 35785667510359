import {
  Box,
  Divider,
  Flex,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandWhatsapp,
} from "@tabler/icons-react";
import backgroundImage from "../assets/Redhead-QHD.webp";
import mobileBackgroundImage from "../assets/Redhead-Mobile.webp";
import FullBackground from "../layout/FullBackground";
import styles from "./Page.module.css";
import { useMediaQuery } from "@mantine/hooks";

const Home = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  return (
    <FullBackground
      id="home"
      backgroundImage={isMobile ? mobileBackgroundImage : backgroundImage}
    >
      {isMobile ? (
        <></>
      ) : (
        <Grid style={{ width: "100%" }} justify="stretch" mt={64}>
          <Grid.Col span={4}></Grid.Col>
          <Grid.Col span={4}></Grid.Col>
          <Grid.Col span={4}>
            <Flex h="100%" justify="center" align="center">
              <Paper
                shadow="lg"
                p="md"
                w={300}
                style={{
                  backgroundColor: "rgba(0, 0, 0, .65)",
                  color: "#fff",
                  fontFamily: "Playfair Display, sans-serif",
                  fontWeight: 100,
                }}
              >
                <Stack>
                  <Text size="xl">
                    Hairdressers are a wonderful breed. You work one on one with
                    another human being, and the object is to make them feel so
                    much better, and to look at themselves with a twinkle in
                    their eye.
                  </Text>
                  <Divider />
                  <Text
                    mt="sm"
                    size="xl"
                    style={{
                      fontFamily: "Great Vibes, cursive",
                      fontWeight: 400,
                    }}
                  >
                    ~ Vidal Sassoon
                  </Text>
                </Stack>
              </Paper>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex h="100%" justify="flex-start" align="flex-end">
              <Group>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialMediaLink}
                >
                  <IconBrandFacebook size={32} />
                </a>
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialMediaLink}
                >
                  <IconBrandInstagram size={32} />
                </a>
                <a
                  href="https://wa.me/+27762171825"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.socialMediaLink}
                >
                  <IconBrandWhatsapp size={32} />
                </a>
              </Group>
            </Flex>
          </Grid.Col>
          <Grid.Col span={9}>
            <Flex h="100%" justify="flex-start" align="flex-end">
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: "20px",
                  borderLeft: "3px solid white",
                }}
              >
                <Title
                  order={1}
                  size={64}
                  style={{
                    color: "rgba(255, 255, 255, .8)",
                    fontFamily: "Playfair Display, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  Our Products, Your Vision
                </Title>
                <Text
                  size="22px"
                  mt="md"
                  ta="left"
                  style={{
                    fontFamily: "Playfair Display, sans-serif",
                    fontWeight: 400,
                  }}
                >
                  At our salon, we don't just cut hair—we transform it. Our
                  highly skilled stylists specialize in creating vibrant,
                  luxurious color treatments that are tailored to your
                  individual style. With advanced training and a passion for
                  hair artistry, they use only the finest professional products
                  to ensure your hair not only looks incredible but feels
                  healthy too.
                </Text>
                <a href="#products" style={{ textDecoration: "none" }}>
                  <Text mt="xl" c="red">
                    Discover →
                  </Text>
                </a>
              </Box>
            </Flex>
          </Grid.Col>
        </Grid>
      )}
    </FullBackground>
  );
};

export default Home;
