import { useState } from "react";
import {
  Flex,
  Group,
  Input,
  Stack,
  Title,
  Button,
  Textarea,
  Divider,
  Text,
  Container,
} from "@mantine/core";
import { IconMapPin, IconPhone, IconMail } from "@tabler/icons-react";
import FullBackground from "../layout/FullBackground";
import { useMediaQuery } from "@mantine/hooks"; // For handling media queries

const Book = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");

  // Handle mobile view by using a media query hook
  const isMobile = useMediaQuery("(max-width: 768px)");

  // Function to handle form submission
  const handleSubmit = () => {
    const whatsappMessage = `Name: ${name}\nSurname: ${surname}\nMessage: ${message}`;
    const phoneNumber = "+27762171825";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappLink, "_blank");
  };

  // Wrap everything in Container conditionally
  const content = (
    <>
      <Stack align={isMobile ? "center" : "left"}>
        <Title
          order={1}
          size={isMobile ? 32 : 48} // Smaller title on mobile
          ta={isMobile ? "center" : "left"}
          style={{
            color: "rgba(255, 255, 255, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Book Now
        </Title>
        <Title
          order={1}
          size={isMobile ? 20 : 24} // Smaller subtitle on mobile
          ta={isMobile ? "center" : "left"}
          style={{
            color: "rgba(255, 255, 255, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Give us a call today and book an appointment in one of our salons.
        </Title>
      </Stack>
      <Flex
        direction={isMobile ? "column" : "row"} // Stack on mobile, row on desktop
        style={{ marginTop: "2rem", width: "100%" }}
        justify="center"
        align="center"
        gap="xl"
      >
        {/* Left side form */}
        <Stack gap="lg">
          <Input
            size="xl"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: isMobile ? "100%" : 450,
            }}
          />
          <Input
            size="xl"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            style={{
              width: isMobile ? "100%" : 450,
            }}
          />
          <Textarea
            size="xl"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{
              width: isMobile ? "100%" : 450,
            }}
          />
          <Button
            size="xl"
            style={{ marginTop: "1rem", width: isMobile ? "100%" : 450 }}
            onClick={handleSubmit}
          >
            Submit →
          </Button>
        </Stack>

        {/* Vertical divider */}
        {!isMobile && <Divider orientation="vertical" size="lg" c="white" />}

        {/* Right side content */}
        <Stack gap="lg">
          <Group align="center">
            <IconMapPin size={isMobile ? 20 : 24} color="white" />
            <Text
              style={{
                color: "rgba(255, 255, 255, .8)",
                fontFamily: "Playfair Display, sans-serif",
                fontSize: isMobile ? 16 : 20, // Adjust text size
              }}
            >
              366 Hippo Ave, Zwartkop, Centurion
            </Text>
          </Group>

          <Group align="center">
            <IconPhone size={isMobile ? 20 : 24} color="white" />
            <Text
              style={{
                color: "rgba(255, 255, 255, .8)",
                fontFamily: "Playfair Display, sans-serif",
                fontSize: isMobile ? 16 : 20,
              }}
            >
              +27 076 217 1825
            </Text>
          </Group>

          <Group align="center">
            <IconMail size={isMobile ? 20 : 24} color="white" />
            <Text
              style={{
                color: "rgba(255, 255, 255, .8)",
                fontFamily: "Playfair Display, sans-serif",
                fontSize: isMobile ? 16 : 20,
              }}
            >
              admin@thecolorstudio.co.za
            </Text>
          </Group>
        </Stack>
      </Flex>
    </>
  );

  return (
    <FullBackground
      backgroundColor="#000000"
      id={"book"}
      borderRadius="32px 32px 0px 0px"
    >
      <Flex
        direction="column"
        style={{ width: "100%" }}
        justify="center"
        align="center"
      >
        {" "}
        {isMobile ? content : <Container>{content}</Container>}
      </Flex>
    </FullBackground>
  );
};

export default Book;
