import {
  Container,
  Flex,
  Paper,
  SimpleGrid,
  Title,
  useMantineTheme,
  Text,
  Box,
} from "@mantine/core";
import FullBackground from "../layout/FullBackground";
import { useMediaQuery } from "@mantine/hooks";
import styles from "./Page.module.css";

const Services = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  return (
    <FullBackground id={"services"} backgroundColor={"#FFFFFF"}>
      <Flex direction="column" style={{ width: "100%" }}>
        <Title
          order={1}
          size={64}
          m={32}
          style={{
            color: "rgba(0, 0, 0, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Services
        </Title>
        <Container fluid>
          <SimpleGrid cols={isMobile ? 1 : 3}>
            <Paper className={styles.serviceCard}>
              <Title className={styles.serviceTitle}>HAIR STYLING</Title>
              <Text ta="left" className={styles.serviceText}>
                Revamp your look with our expert hair styling. From trendy cuts
                to elegant updos, our stylists craft a personalized style just
                for you. Walk out feeling fabulous and confident!
              </Text>
            </Paper>
            <Paper className={styles.serviceCard}>
              <Title className={styles.serviceTitle}>COLOURING</Title>
              <Text ta="left" className={styles.serviceText}>
                Add a splash of color with our top-notch colouring services.
                Whether you want bold hues or subtle highlights, we’ll help you
                find the perfect shade to brighten your look and boost your
                confidence.
              </Text>
            </Paper>
            <Paper className={styles.serviceCard}>
              <Title className={styles.serviceTitle}>TREATMENTS</Title>
              <Text ta="left" className={styles.serviceText}>
                Pamper your hair with our rejuvenating treatments. From deep
                conditioning to repair and hydration, our treatments will leave
                your hair healthier, shinier, and more vibrant.
              </Text>
            </Paper>
          </SimpleGrid>
        </Container>
        <Title
          order={1}
          size={32}
          m={32}
          style={{
            color: "rgba(0, 0, 0, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Operating Hours
        </Title>
        <Container fluid>
          <SimpleGrid cols={isMobile ? 1 : 5} spacing={16}>
            <Box className={styles.dayContainer}>
              <Title className={styles.dayContainerTitle}>Tue</Title>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("https://cdn.britannica.com/53/136653-050-E8AE6706/Hairdresser-curling-iron.jpg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 0 /* Ensure it's below the text */,
                }}
              />
              <Box className={styles.dayContainerTime}>
                <Text size="xl">7:00 - 18:00</Text>
              </Box>
            </Box>

            <Box className={styles.dayContainer}>
              <Title className={styles.dayContainerTitle}>Wed</Title>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("https://www.pembrokeshire.ac.uk/wp-content/uploads/2022/02/hairdresser-drying-hair-female-hairdressing-2021-08-26-16-26-32-utc-1024x683.jpg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 0,
                }}
              />
              <Box className={styles.dayContainerTime}>
                <Text size="xl">7:00 - 18:00</Text>
              </Box>
            </Box>

            <Box className={styles.dayContainer}>
              <Title className={styles.dayContainerTitle}>Thu</Title>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("https://static01.nyt.com/images/2016/01/03/travel/03DOMINICAN1/03DOMINICAN1-articleLarge.jpg?quality=75&auto=webp&disable=upscale")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 0,
                }}
              />
              <Box className={styles.dayContainerTime}>
                <Text size="xl">7:00 - 18:00</Text>
              </Box>
            </Box>

            <Box className={styles.dayContainer}>
              <Title className={styles.dayContainerTitle}>Fri</Title>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("https://assets-global.website-files.com/5bcdaad41740e348e1bb4a8a/5bcdabfb1fb2098b22c6a0e1_17.jpg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 0,
                }}
              />
              <Box className={styles.dayContainerTime}>
                <Text size="xl">7:00 - 18:00</Text>
              </Box>
            </Box>

            <Box className={styles.dayContainer}>
              <Title className={styles.dayContainerTitle}>Sat</Title>
              <Box
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage:
                    'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("https://assets-global.website-files.com/5bcdaad41740e348e1bb4a8a/5bcdabfb1fb2098b22c6a0e1_17.jpg")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 0,
                }}
              />
              <Box className={styles.dayContainerTime}>
                <Text size="xl">7:00 - 17:00</Text>
              </Box>
            </Box>
          </SimpleGrid>
        </Container>
      </Flex>
    </FullBackground>
  );
};

export default Services;
