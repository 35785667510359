import {
  Box,
  Image,
  Title,
  useMantineTheme,
  Container,
  Flex,
  SimpleGrid,
} from "@mantine/core";
import BlondeMe from "../assets/Products/blonde_me.webp";
import IgoraRoyal from "../assets/Products/igora_royal.webp";
import Bonacure from "../assets/Products/bonacure.webp";
import FullBackground from "../layout/FullBackground";
import { useMediaQuery } from "@mantine/hooks";

const Products = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  return (
    <FullBackground
      id={"products"}
      backgroundColor={"#000000"}
      borderRadius="0px 0px 32px 32px"
    >
      <Flex direction="column" style={{ width: "100%" }}>
        <Title
          order={1}
          size={64}
          m={32}
          style={{
            color: "rgba(255, 255, 255, .8)",
            fontFamily: "Playfair Display, sans-serif",
            fontWeight: 400,
          }}
        >
          Products
        </Title>
        <Container fluid>
          <SimpleGrid cols={isMobile ? 1 : 3} spacing="md">
            <Box style={{ width: "100%", height: "auto" }}>
              <Image
                src={Bonacure}
                fit="cover"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Box style={{ width: "100%", height: "auto" }}>
              <Image
                src={BlondeMe}
                fit="cover"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Box style={{ width: "100%", height: "auto" }}>
              <Image
                src={IgoraRoyal}
                fit="cover"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </SimpleGrid>
        </Container>
      </Flex>
    </FullBackground>
  );
};

export default Products;
