import { useState, useEffect } from "react";
import {
  Group,
  Burger,
  Drawer,
  useMantineTheme,
  Stack,
  Image,
  Text,
  Flex,
  Title,
  Box,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import styles from "./Layout.module.css";
import LogoWhite from "../assets/logo_transparent_no_wording_white.png";

const Navbar = () => {
  const [opened, setOpened] = useState(false);
  const [navBackground, setNavBackground] = useState("transparent");
  const [logoSize, setLogoSize] = useState(64);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = 500;
      const darkColor = "#000000";

      // Background color change logic
      if (scrollPosition > maxScroll) {
        setNavBackground(
          `rgba(${darkColor.slice(1, 3)}, ${darkColor.slice(
            3,
            5
          )}, ${darkColor.slice(5, 7)}, 1)`
        );
      } else {
        const opacity = scrollPosition / maxScroll;
        const rgbaColor = `rgba(${parseInt(
          darkColor.slice(1, 3),
          16
        )}, ${parseInt(darkColor.slice(3, 5), 16)}, ${parseInt(
          darkColor.slice(5, 7),
          16
        )}, ${opacity})`;
        setNavBackground(rgbaColor);
      }

      // Logo size change logic
      const newLogoSize = Math.max(
        logoSize,
        logoSize - (scrollPosition / maxScroll) * 32
      );
      setLogoSize(newLogoSize);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme.colors.dark]);

  const handleLinkClick = () => {
    setOpened(false);
  };

  const goHome = () => {
    window.location.href = "#home";
  };

  return (
    <nav
      style={{
        padding: "0px 32px",
        position: "fixed",
        left: 0,
        right: 0,
        zIndex: 10,
        height: 64,
        backgroundColor: navBackground,
        transition: "background-color 0.3s ease, position 0.3s ease",
        overflow: "hidden",
      }}
    >
      {isMobile ? (
        <>
          <Group justify="space-between" p="16px 0px">
            <Image src={LogoWhite} h={32} onClick={goHome} />

            <Burger
              size="md"
              color="white"
              opened={opened}
              onClick={() => setOpened((o) => !o)}
            />
          </Group>
          <Drawer
            opened={opened}
            onClose={() => setOpened(false)}
            padding="xl"
            color="black"
            withCloseButton={false}
          >
            <Flex
              p={32}
              style={{
                backgroundColor: "black",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Flex c="white" justify="flex-end" align="center">
                <Title onClick={() => setOpened(false)} size={24}>
                  X
                </Title>
              </Flex>
              <Stack gap={16}>
                <Box>
                  <a
                    href="#products"
                    onClick={handleLinkClick}
                    className={styles.link}
                  >
                    <Text size="xl">Products</Text>
                  </a>
                </Box>
                <Box>
                  <a
                    href="#services"
                    onClick={handleLinkClick}
                    className={styles.link}
                  >
                    <Text size="xl">Services</Text>
                  </a>
                </Box>
                <Box>
                  <a
                    href="#book"
                    onClick={handleLinkClick}
                    className={styles.link}
                  >
                    <Text size="xl">Book</Text>
                  </a>
                </Box>
              </Stack>
            </Flex>
          </Drawer>
        </>
      ) : (
        <Group justify="space-between">
          <a href="#home">
            <Image src={LogoWhite} h={logoSize} />
          </a>

          <Group gap={32}>
            <a href="#products" className={styles.link}>
              <Text size="xl">Products</Text>
            </a>
            <a href="#services" className={styles.link}>
              <Text size="xl">Services</Text>
            </a>
            <a href="#book" className={styles.link}>
              <Text size="xl">Book</Text>
            </a>
          </Group>
          {/* <Button
              variant="white"
              size="md"
              className={animate ? styles.button : ""}
            >
              Book Now!
            </Button> */}
        </Group>
      )}
    </nav>
  );
};

export default Navbar;
