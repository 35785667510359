import { Container } from "@mantine/core";
import React, { ReactNode, CSSProperties } from "react";

interface FullBackgroundProps {
  backgroundImage?: string;
  backgroundColor?: string;
  children: ReactNode;
  borderRadius?: string;
  id?: string; // Adding id as an optional prop
}

const FullBackground: React.FC<FullBackgroundProps> = ({
  backgroundImage,
  backgroundColor,
  children,
  borderRadius,
  id, // Destructure the id prop
}) => {
  const style: CSSProperties = {
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    color: "#fff",
    textAlign: "center",
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
    backgroundColor: backgroundColor || undefined,
    padding: "32px",
    overflow: "hidden",
    boxSizing: "border-box",
    borderRadius: borderRadius ? `${borderRadius}` : undefined,
  };

  return (
    <Container id={id} fluid style={style}>
      {children}
    </Container>
  );
};

export default FullBackground;
